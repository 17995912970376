import { useRouter } from 'next/router';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import ContentWrapper from '@utils/style';
import SchoolsInfo from './schoolsInfo';
SwiperCore.use([Navigation, Pagination, Autoplay]);
// bg-[#f5f3fa] hover:bg-[#eeeaf7
const Schools = ({ data }) => {
  const router = useRouter();
  function truncate(str, n) {
    return str && str?.length > n ? str.slice(0, n - 1) + '...' : str;
  }
  return (
    <div className="bg-white pt-20 z-10">
      <div
        className="relative max-w-7xl mx-auto space-y-10 px-4 md:px-8"
        data-aos="fade-right"
      >
        <div className="flex justify-between items-start">
          <h1 className="font-bold text-2xl uppercase text-black md:text-3xl">
            <b className="text-mainc">Эдүлайн</b> хамтрагч их сургуулиуд
          </h1>
          <a
            href="/schools"
            className="bg-yellowc hover:scale-105 cursor-pointer text-white p-2 px-3 rounded text-[14px]"
          >
            Бүгд
          </a>
        </div>
        <ContentWrapper>
          <Swiper
            slidesPerView={1.2}
            spaceBetween={10}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              940: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              600: {
                slidesPerView: 2.4,
                spaceBetween: 10,
              },
            }}
          >
            {data &&
              data?.map((school, i) => (
                <SwiperSlide key={i}>
                  <div
                    onClick={() => router.push(`/schools/${school?.id}`)}
                    className="cursor-pointer rounded-lg bg-gradient-to-tr from-[#eaf5f8] to-[#f8f1f8] hover:opacity-90 hover:shadow p-4 md:p-8 h-96"
                  >
                    <SchoolsInfo school={school} />
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </ContentWrapper>
      </div>
    </div>
  );
};
export default Schools;
